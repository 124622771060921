import React from "react"
import styled from "@emotion/styled"
import Recipes from "../templates/Inspirations"
import { graphql } from "gatsby"
import PostListPage from "../components/commons/post-list-page"

const Cursos = ({ data }) => {
  const options = {
    title: 'Cursos',
    titleColor: "#D7C0CF",
    titleBg: "#789A78",
  }

  return (
    <PostListPage options={options}>
      {
        data?.allWpCourse.edges.length
        ? (<Recipes data={data.allWpCourse} />)
        : (<Soon>EM BREVE</Soon>)
      }
      
    </PostListPage>
  )
}

export default Cursos

export const query = graphql`
  query Courses {
    allWpCourse(limit: 10) {
      edges {
        node {
          featuredImage {
            node {
              altText
              srcSet
            }
          }
          title
          slug
          link
        }
      }
    }
  }
`

const Soon = styled.div`
  min-height: 55vh;
  display: flex;
  justify-content: center;
  background-color: #789A78;
  font-size: 3rem;
  color: #D7C0CF;
  font-family: "Altissima Condensed", sans-serif;
  margin-bottom: -55px;
`
